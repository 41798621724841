import { useCallback } from "react";

import PortalLookAndFeel, {
  PortalLookAndFeelRecord,
} from "../entities/PortalLookAndFeel";
import useNetSuiteService from "./useNetSuiteService";

const usePortalLookAndFeelService = () => {
  const { getByIdFromNetSuite } = useNetSuiteService<PortalLookAndFeelRecord>({
    resource: "customrecord_ucp_portal_look_n_feels",
  });

  const getById = useCallback(
    async (id: string) => {
      const result = await getByIdFromNetSuite(id, {
        columns: [
          "custrecord_ucp_returns_logo",
          "custrecord_ucp_returns_favicon",
          "custrecord_ucp_returns_bckg_image",
          "custrecord_ucp_returns_bckg_color",
          "custrecord_ucp_returns_text_color",
          "custrecord_ucp_returns_button_shape",
          "custrecord_ucp_retruns_button_style",
          "custrecord_ucp_returns_link_text_color",
          "custrecord_ucp_returns_primary_color",
          "custrecord_ucp_returns_headings_color",
          "custrecord_ucp_header_text_1",
          "custrecord_ucp_header_link_1",
          "custrecord_ucp_header_text_2",
          "custrecord_ucp_header_link_2",
          "custrecord_ucp_header_text_3",
          "custrecord_ucp_header_link_3",
          "custrecord_ucp_returns_title",
          "custrecord_ucp_returns_company_name",
          "custrecord_ucp_returns_slogan",
          "custrecord_ucp_returns_footer_s1_title",
          "custrecord_ucp_returns_footer_s1_text_1",
          "custrecord_ucp_returns_footer_s1_link_1",
          "custrecord_ucp_returns_footer_s1_text_2",
          "custrecord_ucp_returns_footer_s1_link_2",
          "custrecord_ucp_returns_footer_s1_text_3",
          "custrecord_ucp_returns_footer_s1_link_3",
          "custrecord_ucp_returns_footer_s2_text_1",
          "custrecord_ucp_returns_footer_s2_link_1",
          "custrecord_ucp_returns_footer_s2_title",
          "custrecord_ucp_returns_footer_s2_text_2",
          "custrecord_ucp_returns_footer_s2_link_2",
          "custrecord_ucp_returns_footer_s2_text_3",
          "custrecord_ucp_returns_footer_s2_link_3",
          "custrecord_ucp_returns_footer_s3_title",
          "custrecord_ucp_returns_footer_s3_text_1",
          "custrecord_ucp_returns_footer_s3_link_1",
          "custrecord_ucp_returns_footer_s3_text_2",
          "custrecord_ucp_returns_footer_s3_link_2",
          "custrecord_ucp_returns_footer_s3_text_3",
          "custrecord_ucp_returns_footer_s3_link_3",
          "custrecord_ucp_returns_footer_text_1",
          "custrecord_ucp_returns_footer_link_1",
          "custrecord_ucp_returns_footer_text_2",
          "custrecord_ucp_returns_footer_link_2",
          "custrecord_ucp_returns_footer_text_3",
          "custrecord_ucp_returns_footer_link_3",
          "custrecord_ucp_returns_help_link",
          "custrecord_ucp_returns_footer_image",
          "custrecord_ucp_returns_your_account_link",
          "custrecord_ucp_html_text",
          "custrecord_ucp_top_banner_text",
          "custrecord_ucp_image_banner",
          "custrecord_ucp_html_banner_mobile",
          "custrecord_ucp_image_banner_mobile",
          "custrecord_ucp_image_banner_link",
          "custrecord_ucp_image_banner_mobile_link",
          "custrecord_ucp_returns_tc_link",
          "custrecord_ucp_google_analytics_code_lf",
          "custrecord_ucp_navbar_background_color",
          "custrecord_ucp_navbar_link_color",
          "custrecord_ucp_home_title_text",
          "custrecord_ucp_home_title_color",
          "custrecord_ucp_home_description_text",
          "custrecord_ucp_home_title_text_shipping",
          "custrecord_ucp_home_title_color_shipping",
          "custrecord_ucp_home_desc_text_shipping",
          "custrecord_ucp_under_maintenance",
          "custrecord_ucp_logo_url",
          "custrecord_ucp_zip_postal_code",
        ],
      });

      return PortalLookAndFeel.parsePortalLookAndFeelRecord(result);
    },
    [getByIdFromNetSuite]
  );

  return { getById };
};

export default usePortalLookAndFeelService;
