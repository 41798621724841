import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import qs from "qs";
import { Input, InputLabel, Box, CircularProgress } from "@mui/material";
import ReactGA from "react-ga4";

import classes from "./Home.module.scss";
import Title from "../../components/Common/Title";
import Button from "../../components/Common/Button";
import Paragraph from "../../components/Common/Paragraph";
import Header from "../../components/Common/Header";
import Path from "../../enums/Path";
import ExternalLink from "../../components/Common/ExternalLink";
import MinorFooter from "../../components/Common/MinorFooter";
import usePortalLookAndFeel from "../../operations/usePortalLookAndFeel";
import useAuth from "../../operations/useAuth";

const Home = () => {
  interface FormData {
    orderNumber: string;
    shippingZipCode: string;
  }

  const { portalLookAndFeel } = usePortalLookAndFeel();

  const { authenticateWithCredentials, reset, isLoading } = useAuth();

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    const { orderNumber, shippingZipCode } = data;
    try {
      const result = await authenticateWithCredentials(
        orderNumber,
        shippingZipCode
      );
      ReactGA.event("searchOrder", { orderNumber, shippingZipCode });
      if (result) {
        reset();
        navigate(
          `${Path.Order}?${qs.stringify({
            "order-number": data.orderNumber,
            "zip-code": data.shippingZipCode,
          })}`
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<FormData>();

  return (
    <div
      style={
        portalLookAndFeel?.backgroundImage
          ? { backgroundImage: `url(${portalLookAndFeel.backgroundImage})` }
          : { backgroundColor: portalLookAndFeel?.backgroundColor ?? "#FFFFFF" }
      }
      className={classNames(classes.mainContainer)}
    >
      <Header />
      <section className={classes.loginContent}>
        <div className={classes.login}>
          <header className={classes.loginHeader}>
            <Title
              className={
                portalLookAndFeel.homeTitleColor !== ""
                  ? classes.homeTitleColor
                  : ""
              }
            >
              {portalLookAndFeel.homeTitleTextShipping !== ""
                ? portalLookAndFeel.homeTitleTextShipping
                : "Track your order"}
            </Title>
            <Paragraph
              size="m"
              className={classNames(
                classes.subtitle,
                classes.homeDescriptionColorShipping
              )}
            >
              {portalLookAndFeel.homeDescriptionTextShipping !== ""
                ? portalLookAndFeel.homeDescriptionTextShipping
                : "Welcome back! Please enter your details."}
            </Paragraph>
          </header>
          <form
            className={classes.trackYourOrderForm}
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <Box className={classes.trackYourOrderFormContainer}>
              <Box>
                <Controller
                  control={control}
                  defaultValue=""
                  {...register("orderNumber", { required: true })}
                  render={({ field }) => (
                    <InputLabel>
                      Order Number
                      <Input {...field} />
                    </InputLabel>
                  )}
                />
                <div className={classes.submitError}>
                  {errors.orderNumber && "Order number is required"}
                </div>
              </Box>
              <Box>
                <Controller
                  control={control}
                  defaultValue=""
                  {...register("shippingZipCode")}
                  render={({ field }) => (
                    <InputLabel>
                      {portalLookAndFeel?.zipPostalCode || "Zip Code"}
                      <Input
                        {...field}
                        inputProps={{
                          minLength: 5,
                          maxLength: 10,
                        }}
                      />
                    </InputLabel>
                  )}
                />

                <div className={classes.submitError}>
                  {Object.values(errors).map((e) => {
                    return e.message;
                  })}
                </div>
              </Box>
            </Box>
            <div className={classes.submitButtonWrapper}>
              <Button kind="primary" type="submit">
                {isLoading ? (
                  <CircularProgress
                    className={classes.circularProgress}
                    size="1rem"
                    sx={{ color: "white" }}
                  />
                ) : (
                  <span>Find my order</span>
                )}
              </Button>
            </div>
          </form>
          {portalLookAndFeel.helpLink && (
            <Paragraph size="xs" className={classes.disclaimer}>
              Can’t find your order?&nbsp;
              <ExternalLink
                to={portalLookAndFeel.helpLink}
                className={classes.helpLink}
              >
                Contact Support
              </ExternalLink>
            </Paragraph>
          )}
        </div>
      </section>
      <MinorFooter isLogin />
    </div>
  );
};

export default Home;
